import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ArrowDown } from "constants/svgLogos";
import "animate.css";
import "components/Header/header.scss";
import FadeUp from "components/FadeUp/index";
import FadeDown from "components/FadeDown/index";
import ProductPopup from "./ProductPopup";
import {
  CompanyList,
  PricingList,
  SeriveList,
  SolutionDataList,
  popupListData
} from "data/Header";
import { imageBaseUrl } from "config/constant";

const navItems = [
  {
    key: "product",
    title: "Products",
    path: "/products",
    data: popupListData,
    left: "15%",
    hasDropdown: true
  },
  {
    key: "service",
    title: "Services",
    path: null,
    data: SeriveList,
    left: "25%",
    hasDropdown: true
  },
  {
    key: "solution",
    title: "Solutions",
    path: null,
    data: SolutionDataList,
    left: "25%",
    hasDropdown: true
  },
  {
    key: "integration",
    title: "Integration",
    path: "/integration",
    hasDropdown: false
  },
  {
    key: "pricing",
    title: "Pricing",
    data: PricingList,
    path: null,
    left: "55%",
    hasDropdown: true
  },
  {
    key: "company",
    title: "Company",
    path: null,
    data: CompanyList,
    left: "65%",
    hasDropdown: true
  }
];

const index = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sideMenu, setSideMenu] = useState(false);
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    product: false,
    service: false,
    solution: false,
    pricing: false,
    integration: false,
    company: false
  });
  const [activeFade, setActiveFade] = useState(null);

  const toggleDropdown = (key) => {
    setDropdowns((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
    setIsMouseLeave(false);
  };

  const handleMouseLeave = (key) => {
    setDropdowns((prev) => ({
      ...prev,
      [key]: false
    }));
    setIsMouseLeave(true);
    setActiveFade(key);
  };

  useEffect(() => {
    if (window.innerWidth < 1200) {
      const initialDropdowns = navItems.reduce(
        (acc, item) => ({
          ...acc,
          [item.key]: true
        }),
        {}
      );
      setDropdowns(initialDropdowns);
    }

    const resizeWindow = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    document.body.style.overflow = sideMenu ? "hidden" : "auto";
  }, [sideMenu]);

  const sideBar = () => {
    setSideMenu(!sideMenu);
  };

  useEffect(() => {
    const initialDropdowns = navItems.reduce(
      (acc, item) => ({
        ...acc,
        [item.key]: sideMenu
      }),
      {}
    );
    setDropdowns(initialDropdowns);
  }, [sideMenu]);

  const renderDropdownContent = (item) => {
    const { key, data, left } = item;

    if (dropdowns[key]) {
      return windowWidth > 1200 ? (
        <FadeUp
          duration="0.5"
          position={windowWidth > 1200}
          left={windowWidth > 1200 ? left : ""}
        >
          <ProductPopup
            popupListData={data}
            navigate={navigate}
            closeSideBar={() => setSideMenu(false)}
            setDropdown={(value) =>
              setDropdowns((prev) => ({ ...prev, [key]: value }))
            }
            title={key}
          />
        </FadeUp>
      ) : (
        <ProductPopup
          popupListData={data}
          navigate={navigate}
          closeSideBar={() => setSideMenu(false)}
          setDropdown={(value) =>
            setDropdowns((prev) => ({ ...prev, [key]: value }))
          }
          title={key}
        />
      );
    }

    if (isMouseLeave && windowWidth > 1200 && activeFade === key) {
      return (
        <FadeDown left={windowWidth > 1200 ? left : ""}>
          <div
            onMouseEnter={() =>
              setDropdowns((prev) => ({ ...prev, [key]: false }))
            }
          >
            <ProductPopup
              popupListData={data}
              navigate={navigate}
              closeSideBar={() => setSideMenu(false)}
              setDropdown={(value) =>
                setDropdowns((prev) => ({ ...prev, [key]: value }))
              }
              title={key}
            />
          </div>
        </FadeDown>
      );
    }
    return null;
  };

  return (
    <header className="header">
      <div className="headerContainer">
        <div className="headerLogo">
          <a href="/">
            <img src={`${imageBaseUrl}qr-logo-dev.png`} alt="Logo" />
          </a>
        </div>

        <ul
          className="headerList"
          style={{
            right: sideMenu ? "0" : "-100%",
            transition: "all 0.5s ease"
          }}
        >
          {navItems.map((item) => (
            <li
              key={item.key}
              className={item.hasDropdown ? "headerProduct" : ""}
              onClick={() => setSideMenu(false)}
              onMouseEnter={() => {
                if (windowWidth > 1200 && item.hasDropdown) {
                  toggleDropdown(item.key);
                  setActiveFade(item.key);
                }
              }}
              onMouseLeave={() => {
                if (windowWidth > 1200 && item.hasDropdown) {
                  handleMouseLeave(item.key);
                }
              }}
            >
              {item.hasDropdown ? (
                item.path ? (
                  <Link to={item.path} className="menu_content">
                    {item.title}
                    {renderDropdownContent(item)}
                  </Link>
                ) : (
                  <span className="menu_content">
                    {item.title}
                    {renderDropdownContent(item)}
                  </span>
                )
              ) : (
                <a className="menu_content" href={item.path}>
                  {item.title}
                </a>
              )}
              {item.hasDropdown && (
                <div
                  className="arrowDown"
                  style={{
                    transform: dropdowns[item.key]
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "all 0.5s ease"
                  }}
                >
                  <ArrowDown />
                </div>
              )}
            </li>
          ))}
        </ul>

        <div className="loginSignUp">
          <Link to="https://app.quickrecruit.com/signin">
            <button className="headerLogIn">Log In</button>
          </Link>
          <a href="/get-started">
            <button className="commonBtn headerSignUp">Get Started</button>
          </a>
          <img
            className="headerHamburger"
            onClick={sideBar}
            src={
              sideMenu
                ? `${imageBaseUrl}header_close.svg`
                : `${imageBaseUrl}header_hamburger.svg`
            }
            alt="Hamburger"
          />
        </div>
      </div>
    </header>
  );
};

export default index;
