export const footerData = [
  [
    {
      title: "Products",
      list: [
        {
          name: "Recruitment Platform",
          path: "/products/recruitment-platform"
        },
        {
          name: "Job Posting Management",
          path: "/products/job-posting-management"
        },
        {
          name: "Resume Data Scout",
          path: "/products/resume-data-scout"
        },
        {
          name: "Job Description Scanner",
          path: "/products/job-description-scanner"
        },
        {
          name: "Top Talent Selector",
          path: "/products/top-talent-selector"
        },
        {
          name: "ATS Resume Checker",
          path: "/products/ats-resume-checker"
        },
        {
          name: "AI Resume Builder",
          path: "/products/ai-resume-builder"
        },
        {
          name: "AI Linkedin Optimization",
          path: "/products/ai-linkedin-optimization"
        },
        {
          name: "Resume-JD Syncer",
          path: "/products/resume-jd-syncer"
        }
      ]
    }
  ],
  [
    {
      title: "Services",
      list: [
        {
          name: "Interview As A Service",
          path: "/services/interview-as-a-service"
        },
        {
          name: "Recruitment Process Outsourcing",
          path: "/services/recruitment-process-outsourcing"
        },
        {
          name: "Agency Recruitment",
          path: "/services/agency-recruitment"
        },
        {
          name: "Campus recruitment",
          path: "/services/campus-recruitment"
        }
      ]
    }
  ],
  [
    {
      title: "Glossary",
      list: [
        {
          name: "Recruitment Platform",
          path: "/glossary/recruitment-platform"
        },
        {
          name: "Interview As A Service",
          path: "/glossary/interview-as-service"
        }
      ]
    },
    {
      title: "Explore",
      list: [
        {
          name: "ATS & CRM Platform",
          path: "/explore/ats-crm-platform"
        },
        {
          name: "AI Recruitment Platform",
          path: "/explore/ai-recruitment-platform"
        }
      ]
    }
  ],
  [
    {
      title: "Comparison",
      list: [
        {
          name: "Zoho Recruit Alternative",
          path: "/comparison/zoho-recruit-alternative"
        },
        {
          name: "Ceipal Alternative",
          path: "/comparison/ceipal-alternative"
        }
      ]
    },
    {
      title: "Others",
      list: [
        {
          name: "About Us",
          path: "/about-us"
        },
        {
          name: "Contact",
          path: "/contact-us"
        },
        {
          name: "Blogs",
          path: "https://blogs.quickrecruit.com"
        }
      ]
    }
  ]
];
