import { imageBaseUrl } from "config/constant";
import {
  ResumeChecker,
  ResumeScout,
  DescriptionScanner,
  TalentSelector,
  JobPost,
  ResumeBuilder,
  LinkOptimization,
  ResumeJD,
  ItService
} from "constants/svgLogos";

export const popupListData = {
  popupListData1: {
    productTitle: "Platform",
    tagClr: "green",
    tagContent: "Organization",
    data: [
      {
        imgLink: `${imageBaseUrl}Recruitment_software.svg`,
        service: "Recruitment Platform",
        feature: "Quickly connect with top talent using our advanced platform",
        location: "/products/recruitment-platform"
      }
    ]
  },
  popupListData2: {
    productTitle: "Recruiters",
    tagClr: "green",
    tagContent: "Free",
    data: [
      {
        img: <JobPost />,
        service: "Job Posting Management",
        feature: "Manage postings across social media platforms from one place",
        location: "/products/job-posting-management"
      },
      {
        img: <ResumeScout />,
        service: "Resume Data Scout",
        feature: "Visualize and validate candidates resumes",
        location: "/products/resume-data-scout"
      },
      {
        img: <DescriptionScanner />,
        service: "Job Description Scanner",
        feature: "Enhance job descriptions to attract more candidates",
        location: "/products/job-description-scanner"
      },
      {
        img: <TalentSelector />,
        service: "Top Talent Selector",
        feature: "Get top resumes matching with your job description",
        location: "/products/top-talent-selector"
      }
    ]
  },
  popupListData3: {
    productTitle: "Candidates",
    tagClr: "green",
    tagContent: "Free",
    data: [
      {
        img: <ResumeChecker />,
        service: "ATS Resume Checker",
        feature: "Optimize your resume and get personalised recommendations",
        location: "/products/ats-resume-checker"
      },
      {
        img: <ResumeBuilder />,
        service: "AI Resume Builder",
        feature: "Build your resume with top quality templates",
        location: "/products/ai-resume-builder"
      },
      {
        img: <LinkOptimization />,
        service: "AI Linkedin Optimization",
        feature: "Get more reach by optimizing your linkedIn profile",
        location: "/products/ai-linkedin-optimization"
      },
      {
        img: <ResumeJD />,
        service: "Resume-JD Syncer",
        feature: "Match your resume with job description",
        location: "/products/resume-jd-syncer"
      }
    ]
  }
};

export const SeriveList = {
  popupListData1: {
    productTitle: "Services",
    tagClr: "green",
    tagContent: "Free",
    data: [
      {
        imgLink: `${imageBaseUrl}Interview_as_a_service_icon.svg`,
        service: "Interview As A Service",
        feature:
          "Revolutionize your hiring with our seamless interview services",
        location: "/services/interview-as-a-service"
      },
      {
        imgLink: `${imageBaseUrl}RPO_.svg`,
        service: "Recruitment Process Outsourcing",
        feature: "Boost recruitment efficiency with our expert outsourcing",
        location: "/services/recruitment-process-outsourcing"
      },
      {
        imgLink: `${imageBaseUrl}Recruitment+as+a+serviceicon.svg`,
        service: "Agency Recruitment",
        feature: "Achieve and transform hiring excellence with us",
        location: "/services/agency-recruitment"
      },
      {
        imgLink: `${imageBaseUrl}Campus_recruitment.svg`,
        service: "Campus recruitment",
        feature: "Discover future leaders with our campus recruitment",
        location: "/services/campus-recruitment"
      }
    ]
  }
};

export const SolutionDataList = {
  popupListData1: {
    productTitle: "Organization Size",
    tagClr: "red",
    tagContent: null,
    data: [
      {
        img: `${imageBaseUrl}startup-menu.svg`,
        service: "Start Ups",
        feature: "Streamlined Hiring Solutions for Startups",
        location: "/solutions/start-ups"
      },
      {
        imgLink: `${imageBaseUrl}Medium.png`,
        service: "Medium",
        feature: "Fueling the Success of Small and Medium Enterprises",
        location: "/solutions/medium"
      },
      {
        imgLink: `${imageBaseUrl}Large.png`,
        service: "Large",
        feature: "Driving Excellence in Large-Scale Recruitment",
        location: "/solutions/large"
      }
    ]
  },
  popupListData2: {
    productTitle: "Organization Type",
    tagClr: "green",
    tagContent: null,
    data: [
      {
        img: <ItService />,

        service: "Staffing Agency",
        feature: "Connecting top talent with leading employers.",
        location: "/solutions/staffing-agency"
      },
      {
        imgLink: `${imageBaseUrl}solutions/Corporate_menu.svg `,
        service: "Corporate",
        feature: "Driving corporate innovation with cutting-edge IT solutions",
        location: "/solutions/corporate"
      }
      // {
      //   img: <Enterprice />,
      //   service: "Enterprises",
      //   feature: "Optimized Hiring for Large Enterprises",
      //   location: "/solutions/enterprise"
      // }
    ]
  },
  popupListData3: {
    productTitle: "By Industry",
    tagClr: "green",
    tagContent: null,
    data: [
      {
        imgLink: `${imageBaseUrl}solutions/IT_Industry_menu.svg`,
        service: "IT Industry",
        feature: "Innovative IT solutions for corporate success.",
        location: "/solutions/it-industry"
      },
      {
        imgLink: `${imageBaseUrl}solutions/Education_Industry_menu.svg`,
        service: "Education Institution",
        feature:
          "Transforming education with advanced technology and innovation",
        location: "/solutions/education-institution"
      }
    ]
  }
};

export const CompanyList = {
  popupListData1: {
    productTitle: "Company",
    tagClr: "red",
    tagContent: "Organization",
    data: [
      {
        imgLink: `${imageBaseUrl}About_us_icon.svg`,
        service: "About Us",
        feature: "Our Journey began to make a difference, discover it!",
        location: "/about-us"
      },
      {
        imgLink: `${imageBaseUrl}Contact_us_icon.svg`,
        service: "Contact",
        feature: "Your Thoughts Matter to Us. Get in Touch and Let’s Chat!",
        location: "/contact-us"
      },
      {
        imgLink: `${imageBaseUrl}Blogs_Icon.svg`,
        service: "Blogs",
        feature: "Innovative Recruitment Insights and Trends",
        location: "https://blogs.quickrecruit.com"
      }
    ]
  }
};

export const PricingList = {
  popupListData1: {
    productTitle: "Pricing",
    tagClr: "green",
    tagContent: "Free",
    data: [
      {
        imgLink: `${imageBaseUrl}Recruitment_platform_pricing.svg`,
        service: "Recruitment Platform",
        feature: "Platform Plans for Smarter Hiring",
        location: "/pricing/recruitment-platform"
      },
      {
        imgLink: `${imageBaseUrl}Interview_as_a_service_pricing.svg`,
        service: "Interview As a Service",
        feature: "Expert-Led Interview Pricing",
        location: "/pricing/interview-as-serivice"
      }
    ]
  }
};
