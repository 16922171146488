const apiPath = process.env.REACT_APP_TOOLS;
const GrantApiPath = process.env.REACT_APP_Grant;
const userService = process.env.REACT_APP_USER_SERVICE;
const appBaseUrl = process.env.REACT_APP_BASE_URL;
const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const colors = {
  white: {
    0: "#ffffff",
    1: "#fbfbfd",
    2: "#fafafa"
  },
  gray: {
    0: "#e5e5e5",
    1: "#525252",
    2: "#70807c",
    3: "#6d7a77",
    4: "#3d484d",
    5: "#ffffff40",
    6: "#677779",
    7: "#c4ccca",
    8: "#2b3235",
    9: "#737373",
    10: "#292929"
  },
  green: {
    0: "#02B686",
    1: "#f1f9f2",
    2: "#089c78",
    3: "#ddf5e0"
  },
  black: {
    0: "#000000",
    1: "#424242",
    2: "#1A2F51",
    3: "#0c1a20",
    4: "#bdcbca"
  },
  blue: {
    0: "#183b56",
    1: "#5a7184",
    2: "#0c5aec"
  }
};

export { apiPath, GrantApiPath, userService, appBaseUrl, imageBaseUrl, colors };
